import { computed, reactive } from 'vue'

import available from './features.json'

export const namespace = 'com.max.lilypad.previews'
export const deprecatedFeatures = ['user.settings']

const state = reactive({
  enabled: new Set(JSON.parse(localStorage.getItem(namespace) || '[]')),
  available
})

const updateEnabled = enabled => {
  state.enabled = new Set(enabled)
  localStorage.setItem(namespace, JSON.stringify(Array.from(state.enabled)))
}

const enableFeature = feature => {
  updateEnabled([...state.enabled, feature])
}

const disableFeature = feature => {
  updateEnabled([...state.enabled].filter(f => f !== feature))
}

const enabled = computed({
  get: () => [...state.enabled],
  set: updateEnabled
})

const has = feature => state.enabled.has(feature)

// Remove deprecated features
deprecatedFeatures.forEach(disableFeature)

export default {
  enabled,
  available,
  enableFeature,
  disableFeature,
  has
}
